import React from 'react';
import './App.sass';
import Content from './components/Content';
import Footer from './components/Footer';
import Header from './components/Header';
import { PopupProvider } from './utils/PopupContext';

function App() {
  return (
    <PopupProvider>
      <div className="App">
        <Header></Header>
        <Content></Content>
        <Footer></Footer>
      </div>
    </PopupProvider>
  );
}

export default App;
