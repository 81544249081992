import styled from 'styled-components';
import { colorLight } from '../../assets/assets';
import { usePopup } from '../../utils/PopupContext';
import Link from '../common/Link/Link';

export default function Popup(){
  const { hidePopup } = usePopup();
  const Backdrop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000cf;
  `

  const PopupContent = styled.div`
    padding: 30px;
    background-color: ${colorLight}
  `
  return(
    <Backdrop onClick={hidePopup}>
      <PopupContent>
        <h2 className="headline h2 mb-20">Impressum</h2>
        <p>L.O.F.D.S UG & Co. KG (haftungsbeschränkt)</p>
        <p>Josef-Orlopp-Str. 92-106</p>
        <p className="mb-20">10365 Berlin</p>
        <h2 className="headline h2 mb-20">Kontakt</h2>
        <p>Telefon: <Link link="tel:004915750178119" text="+4915750178119"></Link></p>
        <p className="mb-20">E-Mail: <Link link="email:office@lofds.de" text="office[at]lofds.de"></Link></p>
        <h2 className="headline h2 mb-20">Vertretungsberechtigter Geschäftsführer:</h2>
        <p>KG Komplementär: L.O.F.D.S. UG & Co. KG Management UG (haftungsbeschränkt)</p>
        <p className="mb-20">KG Kommanditisten: Antonia Hammermann, Florin Kerber, Aline Lutz </p>
        <p>HRA: 56027 </p>
        <p>Registergericht: Amtsgericht Charlottenburg</p>
        <p className="mb-20">Umsatzsteueridendifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE 37 162 50602</p>
        <span className="link text-left" onClick={hidePopup}>Schließen</span>
      </PopupContent>
    </Backdrop>
  )
}
