import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';

// Definiere die Form des Kontexts
interface PopupContextType {
  isVisible: boolean;
  showPopup: () => void;
  hidePopup: () => void;
  togglePopup: () => void;
}

// Erstelle den Kontext mit einem Default-Wert von undefined
const PopupContext = createContext<PopupContextType | undefined>(undefined);

// Definiere die Props für den Provider
interface PopupProviderProps {
  children: ReactNode;
}

// Erstelle einen Provider für den Kontext
export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showPopup = useCallback(() => setIsVisible(true), []);
  const hidePopup = useCallback(() => setIsVisible(false), []);
  const togglePopup = useCallback(() => setIsVisible(prev => !prev), []);

  return (
    <PopupContext.Provider value={{ isVisible, showPopup, hidePopup, togglePopup }}>
      {children}
    </PopupContext.Provider>
  );
}

// Custom Hook für den Kontext
export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
}
